import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderImage from "../components/image-header"

const PrivatLivsPolitik = () => (
  <Layout noHeaderImage={true}>
    <SEO title="Privatlivs-politik" />
    <HeaderImage />
    <h1 style={{ marginTop: "40px" }}>PRIVATLIVSPOLITIK HOS STUDIO GAFNI</h1>
    <h2>PERSONOPLYSNINGER</h2>
    <h3>Generelt</h3>
    <p>
      Personoplysninger er alle former for informationer, som i et eller andet
      omfang kan henføres til dig. Når du benytter vores website, så indsamler
      og behandler vi en række af sådanne informationer. Det sker f.eks. ved
      helt almindelige interaktioner på hjemmesiden som fx at du tilmelder dig
      vores nyhedsbrev, deltager i en konkurrence/undersøgelse, registrere dig
      som ny bruger/abonnent eller ved øvrig brug af services eller køb via
      vores hjemmeside. I det omfang du selv giver eksplicit samtykke og selv
      indtaster informationerne, behandler vi desuden: Navn, telefonnummer,
      e-mail, adresse og betalingsoplysninger. Det sker typisk i forbindelse
      med, at du opretter et login eller ved køb på siden. BEMÆRK: DENNE
      HJEMMESIDE BENYTTER IKKE COOKIES.
    </p>
    <h3>Sikkerhed</h3>
    <p>
      Vi behandler dine personoplysninger sikkert og fortroligt i
      overensstemmelse med gældende lovgivning, herunder persondataforordningen
      og databeskyttelsesloven. Dine oplysninger vil alene blive anvendt til det
      formål, de er indsamlet til og vil blive slettet, når dette formål er
      opfyldt eller ikke længere er relevant. Vi har truffet tekniske og
      organisatoriske foranstaltninger mod, at dine oplysninger hændeligt eller
      ulovligt bliver slettet, offentliggjort, fortabt, forringet eller kommer
      til uvedkommendes kendskab, misbruges eller i øvrigt behandles i strid med
      lovgivningen.
    </p>
    <h3> Periode for opbevaring</h3>
    <p>
      Vi opbevarer oplysninger i det tidsrum, som er tilladt i henhold til
      lovgivningen, og vi sletter dem, når de ikke længere er nødvendige eller
      relevante. Perioden afhænger af, hvilken karakter oplysningen er og
      baggrunden for opbevaring. Det er derfor ikke muligt at give en generel
      tidsramme for, hvornår informationer slettes.
    </p>
    <h3> Videregivelse af oplysninger</h3>
    <p>
      Vi benytter derudover en række tredjeparter til opbevaring og behandling
      af data. Disse behandler udelukkende oplysninger på vegne af os og må ikke
      anvende dem til egne formål. Videregivelse af personoplysninger som navn
      og e-mail m.m. vil kun ske, hvis du selv giver samtykke til det. Vi
      anvender kun databehandlere i EU eller i lande, der kan give dine
      oplysninger en tilstrækkelig beskyttelse.
    </p>
    <h3>Indsigt og klager</h3>
    <p>
      Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om dig
      i et almindeligt format (dataportabilitet). Du kan desuden til enhver tid
      gøre indsigelse mod, at oplysningerne anvendes. Du kan også tilbagekalde
      dit samtykke til, at der bliver behandlet oplysninger om dig. Hvis de
      oplysninger, der behandles om dig, er forkerte har du ret til, at de
      bliver rettet eller slettet. Henvendelse herom kan ske til: 61720245. Hvis
      du vil klage over vores behandling af dine personoplysninger, har du også
      mulighed for at tage kontakt til Datatilsynet. Ønsker du ikke længere, at
      vi skal behandle dine personoplysninger, eller at vi skal begrænse
      behandlingen af dine personoplysninger, kan du også sende os en anmodning
      herom til ovennævnte e-mailadresse.
    </p>
    <h3>Udgiver</h3>
    <p>Websitet ejes og publiceres af:</p>
    <p>Studio Gafni</p>
    <p>CVR: 40871330</p>
    <p>2300 København S</p>
    <p>Telefon: 61720245</p>
    <Link to="/">Gå til forsiden</Link>
  </Layout>
)

export default PrivatLivsPolitik
